import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Thumb from '../../assets/svgs/terms.svg';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';

const TermsPage = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO
      title="Termos de Uso da Central do Frete"
      description="Central do Frete - Termos de Uso"
    />
    <Jumbotron
      background={SECONDARY}
      title="Termos e Condições"
      subtitle="Saiba os termos e condições que implicam ao utilizar a Central do Frete"
      Image={Thumb}
    />
    <Container className="pt-5 pb-5">
      <p>
        Estes Termos e condições gerais aplicam-se ao uso dos serviços
        oferecidos pela Central do Frete, empresa devidamente inscrita no
        CNPJ/MF sob o n° 22.531.311/0001-10, situada na Rua São Paulo, 2333,
        sala 2, bairro Cerâmica, São Caetano do Sul – SP, por meio do site&nbsp;
        <a
          href="www.centraldofrete.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.centraldofrete.com
        </a>
      </p>
      <p>
        Qualquer pessoa, que pretenda utilizar os serviços da Central do Frete
        deverá aceitar os Termos e condições gerais e todas as demais políticas
        e princípios que o regem.
      </p>
      <p>
        <strong>
          A aceitação destes Termos e condições gerais é absolutamente
          indispensável à utilização do site e serviços prestados pela Central
          do Frete.
        </strong>
      </p>
      <p>
        O Usuário deverá ler, certificar-se de haver entendido e aceitar todas
        as condições estabelecidas nos Termos e condições gerais e nas Políticas
        de privacidade, assim como nos demais documentos a eles incorporados por
        referência, antes de seu cadastro como Usuário da Central do Frete.
      </p>
      <p>
        Em caso de dúvidas, acesse&nbsp;
        <Link to="/falecomagente">Contato</Link>
      </p>
      <h4>Natureza do Serviço Prestado</h4>
      <p>
        O Cliente, ora embarcador ou transportador, reconhece que a Central do
        Frete é uma plataforma
        <i>online</i>
        que atua como intermediadora entre embarcador e transportador.
      </p>
      <p>
        O embarcador, serve-se da plataforma online Central do Frete, para
        efetuar cotação de frete de forma rápida, visto se servir de vários
        orçamentos simultaneamente, o que possibilita escolher o que melhor lhe
        atende, além de ter a certeza que as transportadoras integrantes da
        plataforma estão devidamente licenciadas e habilitadas para prestação
        dos serviços ofertados.
      </p>
      <p>
        Já o transportador, serve-se da plataforma online Central do Frete para
        aumentar sua possibilidade de prestação do serviço aos diversos
        embarcadores que utilizam a plataforma online para cotação.
      </p>
      <p>
        Portanto, embarcador e transportador, concordam que a Central do Frete é
        apenas uma intermediadora do frete e que a entrega e remessa da
        mercadoria é de responsabilidade exclusiva da transportadora, sendo
        assim, a Central do Frete não responderá pelo atraso, extravio, danos de
        qualquer natureza ou inércia do remetente fornecedor na postagem da
        mercadoria à transportadora.
      </p>
      <h4>Do Cadastro</h4>
      <p>
        Apenas será confirmado o cadastramento do embarcador que preencher todos
        os campos obrigatórios do cadastro, com informações exatas, precisas e
        verdadeiras. O embarcador declara e assume o compromisso de atualizar os
        dados inseridos em seu cadastro sempre que for necessário.
      </p>
      <p>
        Comunicações realizadas pela Central do Frete para os meios de
        comunicação indicados no cadastro serão compreendidos como entregues em
        12 horas da hora da comunicação, caso não tenha resposta em período
        inferior.
      </p>
      <p>
        Ao se cadastrar na Central do Frete, o embarcador e transportador
        poderão utilizar todos os serviços disponibilizados pela empresa.
      </p>
      <p>
        O embarcador acessará sua conta através de e-mail (login) e senha e
        compromete-se a não informar a terceiros esses dados,
        responsabilizando-se integralmente pelo uso que deles seja feito.
      </p>
      <p>
        A Central do Frete não se responsabiliza pela correção dos dados
        inseridos por seus usuários.
      </p>
      <p>
        Os usuários garantem e respondem, em qualquer caso, civil e
        criminalmente pela veracidade, exatidão e autenticidade, dos dados
        cadastrados.
      </p>
      <p>
        A Central do Frete se reserva o direito de recusar qualquer solicitação
        de cadastro e de suspender um cadastro previamente aceito, que esteja em
        desacordo com as políticas e regras dos presentes nesse termo.
      </p>
      <p>
        O embarcador e transportador comprometem-se a notificar a Central do
        Frete imediatamente, e por meio seguro, a respeito de qualquer uso não
        autorizado de sua conta, bem como seu acesso não autorizado por
        terceiros.
      </p>
      <p>
        A política de atendimento da Central do Frete para com seus embarcadores
        e transportadores tem como premissa o respeito e bom atendimento,
        portanto, o embarcador entende e concorda que será realizado o bloqueio
        e suspensão dos serviços prestados pela Central do Frete, em caso de
        desrespeito ou agressão verbal, seja contra os colaboradores da Central
        do Frete ou contra os colaboradores das transportadoras.
      </p>
      <p>
        O embarcador e transportador serão os únicos responsáveis pelas
        operações efetuadas em sua conta, uma vez que o acesso só será possível
        mediante a inclusão da senha, que deverá ser de conhecimento e
        propriedade exclusiva dos mesmos.
      </p>
      <p>
        Você será considerado embarcador ou transportador a partir do momento em
        que se cadastrar em nossa plataforma.
      </p>
      <ol>
        <li>
          O embarcador é responsável por fornecer os pesos exatos, tamanhos e
          descrição da carga para embarque;
        </li>
        <li>
          O embarcador entende e concorda que todas as taxas de frete podem
          sofrer variações de acordo com o tipo da carga e localidades de
          difícil acesso;
        </li>
        <li>
          Os serviços de embarque estão disponíveis apenas para pessoas
          jurídicas estabelecidas em território nacional.
        </li>
        <li>
          O embarcador entende e concorda que o embarque só será efetuado
          mediante a emissão e apresentação da Nota Fiscal Eletrônica.
        </li>
        <li>
          O embarcador entende e concorda que deve cumprir todas as exigências
          fiscais e tributárias com relação à mercadoria embarcada, inclusive
          normas interestaduais e de substituição tributária, à qual é o único
          responsável pelo cumprimento.
        </li>
        <li>
          O embarcador entende e concorda que o CNPJ do emissor da Nota Fiscal
          deve ser o mesmo do cadastro
        </li>
        <li>
          O embarcador entende e concorda em garantir que as embalagens das
          cargas estejam em conformidade para serem transportadas;
        </li>
        <li>
          O embarcador entende e concorda em pagar por todos os serviços
          efetivamente prestados pela Central do Frete.
        </li>
      </ol>
      <h4>
        Das Condições para Contratação do Serviço de Intermediação de Fretes.
      </h4>
      <p>
        As transportadoras não efetuam coleta/entrega em andares superiores, a
        carga será coletada/entregue no piso térreo. Havendo a necessidade de
        tal evento o cliente entende e concorda que deverá informar a Central do
        Frete via e-mail e aguardar a aprovação antes de prosseguir com a
        contratação do frete.
      </p>
      <p>
        Para tipos de carga considerados frágeis ou que contenham partes
        consideradas frágeis, como: aço, madeira, plástico, alumínio, cerâmica,
        louça, refratário, vidro, alumínio, partes de veículos, pedras (mármore,
        granito, quartzo), dentre outros o cliente&nbsp;
        <strong>
          entende e concorda que a transportadora poderá recusar o pedido de
          coleta ou poderá solicitar o termo de isenção de responsabilidade de
          avaria.
        </strong>
      </p>
      <p>
        Volumes com peso superior a 50kg são livres de carga (carregamento) e
        descarga (descarregamento) por parte da transportadora.
      </p>
      <p>
        O embarcador entende e concorda que deverá ter pessoas ou máquinas no
        local de coleta e entrega que efetue no processo, sendo que, nem a
        Central do Frete e nem a transportadora tem responsabilidade por
        possíveis danos a mercadoria ou acidente que porventura ocorram.
      </p>
      <p>
        O Serviço oferecido pelas transportadoras cadastradas na Central do
        Frete é apenas para o transporte de carga seca (produtos não perecíveis
        que podem ser transportados independentemente do horário e das condições
        climáticas).
      </p>
      <p>
        O embarcador entende e concorda que para o envio de materiais que fogem
        deste perfil como líquido, perecível, químico entre outros, deverá
        entrar em contato diretamente com a transportadora e verificar se os
        mesmos possuem restrição ou taxas adicionais para o transporte de sua
        carga, informar a Central do Frete via e-mail e aguardar a aprovação
        antes de prosseguir com a contratação do frete.
      </p>
      <h4>Tarifa e Faturamento</h4>
      <p>
        O cadastro na Plataforma Online Central do Frete é gratuito, todavia, ao
        contratar um frete, o embarcador deverá pagar pelo custo do mesmo.
      </p>
      <p>
        A Central do Frete se reserva o direito de modificar, aumentar ou
        excluir tarifas vigentes a qualquer momento.
      </p>
      <p>
        Tais alterações não vigorarão em relação às negociações e anúncios já
        iniciados na data em que tais alterações sejam publicadas.
      </p>
      <p>
        Os valores dos fretes cotados, são baseados em informações fornecidas
        pelo o embarcador.
      </p>
      <p />
      <h5>Fatores que influenciam neste cálculo:</h5>
      <ol>
        <li>O peso total da mercadoria, incluindo a embalagem;</li>
        <li>
          O tamanho das embalagens, suas dimensões e volume de espaço ocupado;
        </li>
        <li>O tipo de carga e embalagem utilizada para o envio;</li>
        <li>O número de volumes que estão sendo enviados;</li>
        <li>O valor da Nota Fiscal</li>
        <li>
          Coleta ou entrega em locais com dificuldade de entrega, de difícil
          acesso ou com restrição de veículos;
        </li>
        <li>Quaisquer outras despesas acessórias aplicáveis ao frete.</li>
        <li>Distância e modais envolvidos.</li>
      </ol>
      <p>
        Ao efetuar a cotação e contratação do frete através da plataforma
        Central do Frete o embarcador está ciente de que as transportadoras irão
        aferir as medidas, a quantidade de volumes e o valor da Nota Fiscal
        embarcada e irão efetuar a cobrança com base nos dados aferidos por ela.
      </p>
      <p>
        Havendo diferença entre os dados da cotação e os dados aferidos pela
        transportadora, o embarcador aceita e concorda que haverá a cobrança do
        valor adicional sobre o frete contratado.
      </p>
      <p>
        <strong>
          Nota: A quantidade de cotações é limitada para embarcadores que não
          efetuam a contratação do frete, para este fim o embarcador deverá
          contratar um plano pago.
        </strong>
      </p>
      <p>
        Não estão incluídos nas tarifas citadas, quaisquer despesas não
        relacionadas com transporte que podem ser aplicadas tais como: despesas
        de armazenamento, multas, impostos, taxas, tarifas, portagens,
        honorários advocatícios e custas judiciais imputáveis com esta remessa e
        / ou todos os litígios relacionados com a expedição. O embarcador assume
        total responsabilidade por estas despesas na medida de sua respectiva
        responsabilidade.
      </p>
      <h4>
        Forma de Pagamento e Conclusão e Cancelamento do Frete Contratado.
      </h4>
      <p>
        Salvo convenção em contrário, os pagamentos de todos os serviços serão
        por meio de boleto bancário, que é emitido em nome do embarcador ou do
        cliente do embarcador quando o frete assim for contratado.
      </p>
      <p>
        O embarcador entende e concorda em realizar o pagamento de acordo com a
        negociação e que o eventual atraso ou não pagamento do mesmo poderá
        acarretar na inclusão de seu nome em órgãos de proteção ao crédito.
      </p>
      <p>
        O embarcador está ciente e concorda que o pagamento do frete contratado
        e de todas as despesas e acessórios não condiciona à conclusão do
        serviço contratado.
      </p>
      <p>
        Se o embarcador desistir do transporte, ou este se tornar inviável, seja
        por falta de documento ou recolhimento tributário exigível, ou
        determinação de órgãos oficiais, ou recusa ou impossibilidade de
        recebimento pelo destinatário, ou qualquer outro motivo, a mercadoria
        será encaminhada ao remetente, que pagará, em qualquer dos casos, os
        acréscimos de despesas decorrentes, mais perdas e danos, se houver,
        sendo que tais pagamentos devem ser efetuados diretamente à Central do
        Frete.
      </p>
      <p>
        Caso o embarcador cancele o frete contratado antes da postagem ou coleta
        da mercadoria, o valor pago a título de frete será devolvido ao
        embarcador.
      </p>
      <p>
        Após o embarque da carga, e até a entrega da coisa, pode o embarcador
        desistir do transporte e pedi-la de volta, ou ordenar que seja entregue
        em outro endereço ou à outro destinatário, pagando, em todos os casos,
        os acréscimos de despesa decorrentes da contra-ordem, inclusive eventual
        armazenagem e/ou descarte, mais as perdas e danos que houver.
      </p>
      <p>
        O cliente concorda em manter seu cadastro atualizado com a Central do
        Frete para eventual devolução de valores que se faça necessária, ainda,
        concorda e entende que em caso de impossibilidade de contato com o
        cliente para devolução, a Central do Frete não se responsabilizará por
        qualquer valor pleiteado a título indenizatório.
      </p>
      <h4>Privacidade</h4>
      <p>
        A Central do Frete leva sua privacidade a sério. Ao utilizar o site da
        Central do Frete você está dando-nos permissão para contatá-lo sobre os
        nossos serviços de frete conforme os termos da nossa Política de
        Privacidade
      </p>
      <h4>Falhas no Sistema</h4>
      <p>
        A Central do Frete não se responsabiliza por qualquer dano, prejuízo ou
        perda sofrido pelo cliente em razão de falhas na internet, no sistema ou
        no servidor, decorrentes de condutas de terceiros, caso fortuito ou
        força maior.
      </p>
      <p>
        A Central do Frete não se responsabiliza por eventuais divergências nos
        endereços informados quando da realização do cadastro, seja para a
        entrega, remessa, entrega, devolução e/ou descarte da mercadoria.
      </p>
      <h4>Responsabilidade</h4>
      <p>
        A Central do Frete não se responsabiliza por impossibilidade ou atrasos
        na coleta, falta de comprovante de coleta/entrega, atrasos no embarque
        da carga, atrasos na entrega da carga, não cumprimento da previsão de
        entrega e avarias na carga, independente por qual motivo, razão ou
        circunstância tenha ocorrido. A responsabilidade do Transporte é da
        transportadora escolhida.
      </p>
      <p>
        A Central do Frete não se responsabiliza pela mercadoria em caso de
        recusa do destinatário por qualquer motivo, devendo o
        embarcador/remetente arcar com todos os custos para remessa, devolução,
        armazenagem ou descarte da mercadoria, bem como eventuais danos causados
        pela conduta.
      </p>
      <p>
        Da mesma forma, a Central do Frete não se responsabiliza pelo pagamento
        do frete em caso de recusa da mercadoria pelo destinatário por qualquer
        motivo, devendo o embarcador/remetente arcar com todos os custos para
        remessa, devolução e/ou descarte da mercadoria, bem como eventuais danos
        causados pela conduta.
      </p>
      <p>
        A Central do Frete não se responsabiliza pela postagem/entrega da
        mercadoria de forma atrasada pelo remetente/fornecedor, tampouco em caso
        de inércia do remetente na postagem/entrega, sendo este o único
        responsável pela entrega da mercadoria à transportadora para remessa,
        bem como eventuais danos causados pela conduta.
      </p>
      <p>
        Se o transporte não puder ser feito ou sofrer longa interrupção, o
        transportador solicitará, incontinenti, instruções ao embarcador,
        através de seu e-mail cadastrado, cuja resposta deverá ser em prazo de
        no máximo 24 horas. E o transportador zelará pela coisa, por cujo
        perecimento ou deterioração responderá, salvo força maior.
      </p>
      <p>
        Caso perdure o impedimento, sem motivo imputável ao transportador e sem
        manifestação do remetente no prazo de 24 horas, poderá o transportador
        depositar a coisa em juízo, ou vendê- la, obedecidos os preceitos legais
        e regulamentares, ou os usos locais, depositando o valor, descontando os
        valores do frete eventualmente ainda não recebido, mais perdas e danos e
        valor da armazenagem ocorrida, devendo o transportador informar o
        remetente da efetivação do depósito ou da venda.
      </p>
      <p>
        Se o transportador mantiver a coisa depositada em seus próprios
        armazéns, continuará a responder pela sua guarda e conservação,
        sendo-lhe devida, porém, uma remuneração pela custódia, conforme tabela
        do transportador.
      </p>
      <p>
        Na ocorrência de qualquer espécie de dano moral ou material e/ou lucros
        cessantes ao destinatário da mercadoria, a Central do Frete não se
        responsabiliza por nenhuma espécie de indenização, sendo os únicos
        responsáveis pela remessa e transporte da mercadoria o Remetente e a
        Transportadora, respectivamente.
      </p>
      <p>
        A Central do Frete não se responsabiliza pelo posterior cancelamento do
        frete pelo Remetente e/ou Destinatário, seja antes ou durante o
        transporte da mercadoria pela Transportadora, devendo o contratante
        arcar com os custos necessários ao cancelamento e, eventualmente,
        remessa, devolução e/ou descarte da mercadoria, bem como eventuais danos
        causados pela conduta.
      </p>
      <h4>Propriedade Intelectual e Links</h4>
      <p>
        Os sites podem manter links com outros sites, o que não significa que
        esses sites sejam de propriedade ou operados pela Central do Frete. Não
        possuindo controle sobre esses sites de terceiros, razão pela qual a
        Central do Frete não será responsável pelos seus conteúdos, práticas e
        serviços ofertados. A presença de links para outros sites não implica
        relação de sociedade, de supervisão, de cumplicidade ou solidariedade da
        Central do Frete para com esses sites e seus conteúdos.
      </p>
      <h3>Legislação Aplicável e Foro de Eleição</h3>
      <p>
        Todos os Termos e Condições Gerais de Uso e Políticas de Privacidade
        serão regidos pelas leis vigentes na República Federativa do Brasil.
        Para todos os assuntos referentes à interpretação, ao cumprimento ou
        qualquer outro questionamento relacionado a estes Termos e Condições e
        Políticas de Privacidade, as partes concordam em se submeter ao Foro da
        Comarca de São Caetano do Sul - SP, renunciando a quaisquer outros por
        mais privilegiado que seja ou venha a ser, salvo os casos em que a
        legislação vigente vede tais eleições ou renúncia.
      </p>
    </Container>
  </Layout>
);

TermsPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default TermsPage;
